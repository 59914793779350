import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import ArtisButton from "../buttons/button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function TransferLink(props) {
  const { transferCode, setOpenTansferBy, setOpenLinkModal, openLinkModal,senderName, workName, allCollabsbyWork } =
    props;

  const handleClose = () => {
    setOpenLinkModal(false);
  };
  const closeModal = () => {
    setOpenTansferBy(false);
    setOpenLinkModal(false);
  };

      const message = `${senderName} is transferring to you an https://artis.app Certificate of Authenticity for the work ${workName} created by ` +
      (allCollabsbyWork && allCollabsbyWork.length > 1
        ? allCollabsbyWork.map((collaborators, index) =>
            index === allCollabsbyWork.length - 1
              ? `and ${collaborators?.profile?.first_name} ${collaborators?.profile?.last_name}`
              : `${collaborators?.profile?.first_name} ${collaborators?.profile?.last_name}${index < allCollabsbyWork.length - 2 ? ', ' : ' '}`
          ).join('')
        : `${allCollabsbyWork[0]?.profile?.first_name} ${allCollabsbyWork[0]?.profile?.last_name}`
      ) +
      `. Click on the link and follow the instructions to accept the Certificate.\n` +
      `https://artis.app/works?transfer_code=${transferCode}`;

    navigator.clipboard.writeText(message).then(() => {
      console.log('Message copied to clipboard!');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });


  return (
    <React.Fragment>
      <Dialog
        open={openLinkModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          <IconButton
            aria-label="go-back"
            onClick={() => setOpenLinkModal(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span
            style={{ marginLeft: "1rem", flexGrow: 1, textAlign: "center" }}
          >
            {"Copy and text the link below to transfer the certificate."}
          </span>
        </DialogTitle>
        
        <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center", whiteSpace:"pre-line"}}
          >
            <Box component="p" fontSize="15px">
              <strong>{senderName}</strong> is transferring to you an <span style={{ color: 'green' }}>https://artis.app</span> Certificate of Authenticity for the work <strong>{workName}</strong> by{" "}
                {
                  allCollabsbyWork && allCollabsbyWork.length > 1 ? (
                    allCollabsbyWork.map((collaborators, index) =>
                      index === allCollabsbyWork.length - 1 ? (
                        <>
                          and{" "}
                          <strong>
                            {collaborators?.profile?.first_name} {collaborators?.profile?.last_name}
                          </strong>
                        </>
                      ) : (
                        <>
                          <strong>
                            {collaborators?.profile?.first_name} {collaborators?.profile?.last_name}
                          </strong>
                          {index < allCollabsbyWork.length - 2 ? ", " : " "}
                        </>
                      )
                    )
                  ) : (
                    <strong>{allCollabsbyWork[0]?.profile?.first_name} {" "} {allCollabsbyWork[0]?.profile?.last_name}</strong>
                  )
                }
                . Click on the link and follow the instructions to accept the Certificate.{"\n"}
                https://artis.app/works?transfer_code={transferCode}

              <Tooltip title="Copy Link">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      message
                    );
                    toast.success("Text copied to clipboard!", {
                      position: "bottom-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <br />
            </Box>
          </DialogContentText>
          <ArtisButton
            name="OK"
            onClick={closeModal}
            id="Cancel"
            sx={{
              fontSize: "1.5rem",
            }}
            textColor="black"
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
