import React, { useState } from "react";
import Box from "@mui/material/Box";
import LocalOffer from "@mui/icons-material/LocalOffer";

import CollapsibleBody from "../../../collapsible";
import { Typography } from "@mui/material";
import ArtisInput from "../../../inputs/textfield";
import ArtisButton from "../../../buttons/button";

const getSaleStatus = (on_sale) => {
  if (on_sale === true) {
    return "On Sale";
  }
  return "Put on Sale";
};

export default function Sell(props) {
  const [saleStatus, setSaleStatus] = useState(
    getSaleStatus(props.cert_on_sale)
  );
  const [price, setPrice] = useState(props?.cert_price || "");
  const [saleDescription, setSaleDescription] = useState(props?.cert_description || "")

  const _handlePutOnSale = async (e) => {
    e.preventDefault();
    const result = await props.handlePutOnSale(price, saleDescription);
    if (result) setSaleStatus("On Sale");
  };

  const _handleCancelSale = async (e) => {
    e.preventDefault();
    const result = await props.handleCancelSale();
    if (result) setSaleStatus("Put on Sale");
  };

  const sellContent = () => (

    <Box ml={3} mr={3} mb={3} component="form" onSubmit={_handlePutOnSale}>
       <br />
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <iframe 
            title="explainer" 
            src="https://player.vimeo.com/video/769159375?h=38198d3f37" 
            style={{ 
              boxShadow: '1px 2px 5px #AAAAAA', 
              width: '100%', 
              height: '100%', 
              maxWidth: '300px', 
              maxHeight: '300px', 
              aspectRatio: '1 / 1' 
            }}  
            allow="autoplay; fullscreen; picture-in-picture" 
            allowFullScreen
            />
          </div>

      {props.cert_on_sale === false &&
          <>
               <Box component="div" m={3}>
                  <Box sx={{fontFamily: "'Montserrat', sans-serif", fontWeight: "normal", fontSize: "15px"}}>
                  You can turn this Artis.app certificate into a sales tag which shows that the work is on sale and for what price. You can add additional information to the sales tag, such as the name of your store and the condition of your sale. Not only does this allow you to sell the work faster, but it also prevents thieves from using this certificate as the certificate clearly shows that this work is still on sale. When you cancel the sale, all the sale information on this certificate will disappear.
                </Box>  
              </Box>
          </>
      }

      <Box textAlign="center">
        <ArtisInput
          value={price}
          label="Price"
          id="sold_price"
          onChange={(e) => setPrice(e.target.value)}
        />
        <ArtisInput
          value={saleDescription}
          label="Describe sale"
          id="sale_description"
          onChange={(e) => setSaleDescription(e.target.value)}
        />
        <ArtisButton
          name="Start Sale"
          size="medium"
          id="start_sale"
          sx={{
            fontSize: "1.5rem",
          }}
        />
      </Box>
    </Box>
  );

  const onSaleContent = () => (
    <Box component="form" ml={3} mr={3} mb={3}>
      <Box color="gray" textAlign="center">
        <br />
        Price displayed on Proof of Registration:
      </Box>
      <br />
      <Box textAlign="center">
        <Box
          component="div"
          style={{
            borderRadius: 10,
            minHeight: 50,
            backgroundColor: "#e0e0e0",
            width: "100%",
            display: "flex",
            flexFlow: "column wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            fontSize: "1.3rem",
            mt: 1,
            fontFamily: "Bellefair, serif",
            fontWeight: 400,
            backgroundColor: "grey",
          }}
        >
          <Typography
            color="teal"
            sx={{
              textDecoration: "underline",
              fontSize: "3rem",
            }}
          >
            {props.cert_price}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            {props.cert_description}
          </Typography>
        </Box>
        <ArtisButton
          name="CANCEL SALE"
          id="cancel_sale"
          onClick={_handleCancelSale}
          size="medium"
          sx={{
            fontSize: "1.5rem",
          }}
          // textColor="black"
        />
      </Box>
    </Box>
  );

  return (
    <CollapsibleBody
      expanded={props.openId === "sell-body"}
      id="sell-body"
      title={saleStatus}
      icon={<LocalOffer />}
      handleClick={props.setOpenId}
      mainSx={{
        alignItems: "center",
        fontSize: "1.3rem",
        fontFamily: "'Bellefair', serif;",
        fontWeight: "normal",
      }}
      buttonProps={{
        textColor:
          saleStatus === "On Sale"
            ? "teal"
            : saleStatus === "Sold"
              ? "brown"
              : "black",
      }}
    >
      {saleStatus === "On Sale" && onSaleContent()}
      {saleStatus === "Put on Sale" && sellContent()}
    </CollapsibleBody>
  );
}
